import { DataAccess } from "../dataaccess/data.access";
import { Timeout } from "../decorators/timeout.decorator";
import { S25Datefilter } from "../modules/s25-dateformat/s25.datefilter.service";
import { EventFormOccurrenceUtil } from "../modules/s25-event-creation-form/occurrences/event.form.occurrence.util";
import { S25ItemI } from "../pojo/S25ItemI";
import { S25Util } from "../util/s25-util";
import { FlsService } from "./fls.service";
import { UserprefService } from "./userpref.service";

let durationNameMap: any = { D: "Day", W: "Week", M: "Month", Y: "Year" };
export interface EventQuotaDetailedI {
    itemId?: number;
    itemName?: string;
    type?: S25ItemI;
    duration?: number;
    durationType?: string;
    durationTypeName?: string;
    useQuantity?: boolean; //Indicates whether quota is count based or time based
    quantity?: number;
    maxTime?: string; //ISODuration
    groupItems?: boolean; //Indicates whether limitation are applied individually or by the group of items
    skipExisting?: boolean; //Quota only applies to this event - requested for embedded and 'guest' events
    organizations?: S25ItemI[]; //Array of locations or organizations
    locations?: S25ItemI[]; //Array of locations or organizations
    securityGroups?: S25ItemI[];
    active?: boolean;
    comment?: string;
    [key: string]: any; //allow any other properties
}

function applyMultiselectStatus(arr: any, retVal: any, prop: string, action: "add" | "remove") {
    let status = action === "add" ? "new" : "del";
    arr &&
        arr.forEach((item: any) => {
            let exists = S25Util.array.getByProp(retVal[prop], "itemId", item.itemId);
            if (exists) {
                exists._status = status;
            } else {
                retVal[prop].push({ itemId: item.itemId, _status: status, itemTypeId: item.itemTypeId });
            }
        });
}
export class EventQuotaService {
    public static quotaTypes = [
        { itemId: 99, itemName: "User" }, // deprecated, "System"},
        { itemId: 4, itemName: "User" }, // deprecated, "Location"},
        { itemId: 3, itemName: "User" },
        { itemId: 2, itemName: "Organization" },
    ];

    public static typeIdToName(id: number) {
        let type = S25Util.array.getByProp(EventQuotaService.quotaTypes, "itemId", id);
        return type && type.itemName;
    }

    public static objToJson(quotas: EventQuotaDetailedI) {
        quotas = S25Util.array.forceArray(quotas);
        return (
            quotas.map((q: any) => {
                let retVal: any = {
                    _status: q.status || q.itemId ? "mod" : "new",
                    active: q.active,
                    quota_id: q.itemId,
                    quota_name: q.itemName,
                    comment: q.comment,
                    quota_type: q.type && q.type.itemId,
                    quantity: q.quantity || 0,
                    max_minutes: S25Util.ISODurationToMinutes(q.maxTime),
                    duration: q.duration,
                    duration_type: q.durationType,
                    group_objects: q.groupItems ? 1 : 0,
                    skip_existing: q.skipExisting ? 1 : 0,
                    organizations: q.organizations || [],
                    locations: q.locations || [],
                    secGroups: q.secGroups
                        ? q.secGroups.map((group: any) => {
                              return { itemId: group.itemId, _status: group.status || "est" };
                          })
                        : [],
                };

                // applyMultiselectStatus(q.addedItems, retVal, "itemIds", "add");
                // applyMultiselectStatus(q.removedItems, retVal, "itemIds", "remove");

                applyMultiselectStatus(q.addedLocations, retVal, "locations", "add");
                applyMultiselectStatus(q.removedLocations, retVal, "locations", "remove");

                applyMultiselectStatus(q.addedOrgs, retVal, "organizations", "add");
                applyMultiselectStatus(q.removedOrgs, retVal, "organizations", "remove");

                applyMultiselectStatus(q.addedGroups, retVal, "secGroups", "add");
                applyMultiselectStatus(q.removedGroups, retVal, "secGroups", "remove");

                return retVal;
            }) || []
        );
    }

    public static jsonToObj(quotas: any): EventQuotaDetailedI[] {
        quotas = S25Util.array.forceArray(quotas);
        return (
            quotas.map((q: any) => {
                const durationTypeName = durationNameMap[q.duration_type] + (q.duration !== 1 ? "s" : "");
                return {
                    itemId: q.quota_id,
                    itemName: q.quota_name,
                    comment: q.comment,
                    active: q.active,
                    type: { itemId: q.quota_type, itemName: EventQuotaService.typeIdToName(q.quota_type) },
                    quantity: q.quantity,
                    duration: q.duration,
                    maxTime: S25Util.minutesToISODuration(q.max_minutes) || "P0DT00H00M",
                    durationType: q.duration_type,
                    durationTypeName,
                    groupItems: !!q.group_objects,
                    skipExisting: !!q.skip_existing,
                    // durationType: {itemId: q.duration_type, itemName: durationNameMap[q.duration_type && q.duration_type.toUpperCase()]},
                    items: S25Util.array.forceArray(q.itemIds),
                    organizations: S25Util.array.forceArray(q.organizations),
                    locations: S25Util.array.forceArray(q.locations),
                    secGroups: S25Util.array.forceArray(q.secGroups),
                };
            }) || []
        );
    }

    @Timeout
    public static getAll(): Promise<EventQuotaDetailedI[]> {
        return DataAccess.get(DataAccess.injectCaller("/event/quotas/list.json", "EventQuotasService.getAll")).then(
            (resp) => {
                let quotas = S25Util.array.forceArray(resp && resp.root && resp.root.quotas);
                return S25Util.isObject(quotas[0]) ? EventQuotaService.jsonToObj(quotas) : null;
            },
        );
    }

    @Timeout
    public static set(quotas: EventQuotaDetailedI[]) {
        let payload = S25Util.array.forceArray(EventQuotaService.objToJson(quotas));
        return DataAccess.put(DataAccess.injectCaller("/event/quotas/edit.json", "EventQuotasService.set"), {
            root: { quotas: payload },
        });
    }

    @Timeout
    public static delete(quotaIds: number[]) {
        quotaIds = S25Util.array.forceArray(quotaIds);
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/event/quotas/delete.json?quota_id=" + quotaIds.join("+"),
                "EventQuotasService.delete",
            ),
        );
    }

    @Timeout
    public static check(occurrences: any, locations?: any[], orgId?: number) {
        // create an event from "more actions" produces this situation
        occurrences = S25Util.array.forceArray(occurrences);
        locations = S25Util.array.forceArray(locations);

        return FlsService.getFls().then((fls) => {
            if (fls.QUOTA_OVERRIDE === "F" || occurrences.length === 0) {
                // Has quota overide bail early
                return [];
            } else {
                let occIdCount = 0;
                orgId = S25Util.parseInt(orgId) || undefined;
                let payload = {
                    root: {
                        organization_id: orgId,
                        locations: locations.map((loc) => {
                            return { room_id: loc.itemId, occ_id: loc.occ_id };
                        }),
                        dates: occurrences
                            .filter((occ: any) => {
                                return occ.state ? occ.state.itemId != 99 : true;
                            })
                            .map((occ: any) => {
                                let startDt = occ.evStartDt ? EventFormOccurrenceUtil.getStartDt(occ) : occ.startDt; //If statement is purely for defense, at one point occ.startDt was sent. Not sure if that still happens - T
                                let endDt = occ.evEndDt ? EventFormOccurrenceUtil.getEndDt(occ) : occ.endDt;
                                let occId = occ.uuid || occIdCount; //default
                                return Object.assign(
                                    {
                                        start_dt: S25Util.date.toS25ISODateTimeStr(startDt),
                                        end_dt: S25Util.date.toS25ISODateTimeStr(endDt),
                                    },
                                    occId && { occ_id: occId },
                                    occ.rsrvId && { rsrv_id: occ.rsrvId },
                                );
                            }),
                    },
                };

                return S25Util.all({
                    post: DataAccess.post(
                        DataAccess.injectCaller("/event/quotas/check.json", "EventQuotaService.check"),
                        payload,
                    ),
                    dateFormat: UserprefService.getS25DateTimeformat(),
                })
                    .then((resp) => {
                        let dateFormat = resp.dateFormat;
                        let post = resp.post && resp.post.root;

                        let orgDates = S25Util.array.forceArray(post && post.org_dates);
                        let userDates = S25Util.array.forceArray(post && post.user_dates);

                        let orgBlocked = EventQuotaService.checkMap(
                            orgDates.filter((item: any) => item.allowed === 0),
                            2,
                            dateFormat,
                        );
                        let userBlocked = EventQuotaService.checkMap(
                            userDates.filter((item: any) => item.allowed === 0),
                            3,
                            dateFormat,
                        );

                        return [].concat(userBlocked, orgBlocked);
                    })
                    .catch((e) => {
                        //don't want an error in this service to disrupt saves that should otherwise be allowed. err toward not enforcing a quota
                        console.error(e);
                        return [];
                    });
            }
        });
    }

    public static checkMap(quotas: any, quotaTypeId: number, dateFormat: any) {
        quotas = S25Util.array.forceArray(quotas);
        return quotas.map((item: any) => {
            let quota = EventQuotaService.jsonToObj([item])[0];
            quota.subjectTypeId = item.quota_type;
            let subject = S25Util.array.getByProp(EventQuotaService.quotaTypes, "itemId", item.quota_type);
            quota.subjectTypeName = subject && subject.itemName;
            return {
                quota: quota,
                occ: {
                    allowed: item.allowed,
                    allowed_time: item.allowed_time,
                    allowed_count: item.allowed_count,
                    uuid: item.occ_id,
                    rsrvId: item.rsrv_id,
                    startDt: S25Datefilter.transform(item.start_dt, dateFormat),
                    endDt: S25Datefilter.transform(item.end_dt, dateFormat),
                    object_id: item.object_id,
                    object_type_id: item.object_type_id,
                },
            };
        });
    }
}
