import { SettingsService } from "./settings.service";
import { PreferenceService } from "./preference.service";
import { S25Util } from "../util/s25-util";
import { SpaceSatisfactionI, SpaceSatisfactionWSI } from "../pojo/SpaceSatisfactionI";
import { FlsService } from "./fls.service";

export const SOC_CA_ID = -83; // NO longer using a custom attribute for score data. Now it comes from spaces service &include=ratings

export class Eval25Service {
    /*
        Returns the url of the for the eval25 instance associated with 25live
     */
    public static getUrl() {}

    public static status = {
        hasLicense: false,
        globalView: false,
        userView: false,
    };

    /*
     * Checks Eval25 license status and sets static var so we can check without needing a promise
     */
    public static async instanceHasEval25(): Promise<boolean> {
        try {
            const settings = await SettingsService.getSettings();
            // return true;
            if (settings["CAN_USE_EVAL25"]) {
                Eval25Service.status.hasLicense = true;
            } else {
                Eval25Service.status = {
                    hasLicense: false,
                    globalView: false,
                    userView: false,
                };
            }

            return !!settings["CAN_USE_EVAL25"];
        } catch (err) {
            Eval25Service.status = {
                hasLicense: false,
                globalView: false,
                userView: false,
            };
            return Promise.resolve(false);
        }
    }

    /*
    Checks the global preference and license requirements for whether location score should be displayed.
     */
    public static async displayLocationScore(): Promise<boolean> {
        if (await Eval25Service.instanceHasEval25()) {
            const pref = await PreferenceService.getPreferences(["Eval25"]);
            const fls = await FlsService.getFls();
            Eval25Service.status.globalView = S25Util.toBool(pref["Eval25"]?.value) && fls["SPACE_RATING"] !== "N";
            Eval25Service.status.userView = false;
            return Eval25Service.status.globalView;
        } else {
            return false;
        }
    }

    public static jsonToObj(json: SpaceSatisfactionWSI[]): SpaceSatisfactionI[] {
        return S25Util.array.forceArray(json).map((rm: SpaceSatisfactionWSI) => {
            return {
                term_code: rm.term_code,
                term_name: rm.term_title,
                term_description: rm.term_description,
                rating: rm.rating_avg,
                rating_median: rm.rating_median,
                start_dt: rm.term_start_dt,
                end_dt: rm.term_end_dt,
            };
        });
    }
    public static sortScores(scores: SpaceSatisfactionI[]): SpaceSatisfactionI[] {
        return S25Util.array.forceArray(scores).sort((a: SpaceSatisfactionI, b: SpaceSatisfactionI) => {
            return S25Util.date.diffMonths(a.end_dt, b.end_dt) < 0 ? 1 : 0;
        });
    }
    public static mostRecentScore(scores: SpaceSatisfactionI[]): SpaceSatisfactionI {
        return Eval25Service.sortScores(scores)[scores.length - 1];
    }
}
