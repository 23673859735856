import { DataAccess } from "../dataaccess/data.access";

export interface UncoveredUrl {
    uncoveredUrlId: number;
    createDt: string;
    requestId?: number;
    component?: Component;
    reason?: string;
    detail?: string;
    errProcedure?: string;
    meta?: string;
    requestLog?: RequestLog;

    [index: string]: any;
}

export interface RequestLog {
    requestId: number;
    procedureName?: string;
    meta?: string;
}

export interface Component {
    component: number;
    componentName: string;
}

export class ErrorsService {
    public static getErrors(): Promise<UncoveredUrl[]> {
        return DataAccess.get("/errors/top.json");
    }
}
