export class ExecutionEnvironment {
    public static run(codeStr: string, thisObj: any) {
        return function (__jsCode__: any) {
            var allowedNames: any = { eval: true, setTimeout: true, console: true, Date: true };
            var disallowedChars = " !@#%^&*()+-=\\<>.?:;|{}[],\"'";
            var disallowedArray = disallowedChars.split("");
            var variableOverwrites = "";

            ["XMLHttpRequest", "ActiveXObject"]
                .concat(Object.keys(window))
                .filter(function (name) {
                    let numDisallowed = disallowedArray.filter(function (da) {
                        return name.indexOf(da) > -1;
                    });
                    return !allowedNames[name] && numDisallowed.length === 0;
                })
                .map(function (name) {
                    try {
                        let overwriteCandidate = "var " + name + " = undefined; ";
                        eval(overwriteCandidate);
                        variableOverwrites += overwriteCandidate;
                    } catch (e) {}
                });

            __jsCode__ =
                variableOverwrites +
                "let $pro = this; " +
                "let __execStartDate__ = new Date(); " +
                "let __terminate__ = function() { return ((new Date()) - __execStartDate__) / 1000 > 3; }; " +
                "var setTimeout = undefined; " +
                __jsCode__;
            __jsCode__ = __jsCode__.replace(/(eval\s*\(.*?\);)/g, "console.log('Cannot use eval');");
            __jsCode__ = __jsCode__.replace(/(while\s*\(.*?\)\s*{)/g, "$1 if(__terminate__()) { break; } ");
            __jsCode__ = __jsCode__.replace(/(do\s*{)/g, "$1 if(__terminate__()) { break; } ");
            __jsCode__ = __jsCode__.replace(/(for\s*\(.*?\)\s*{)/g, "$1 if(__terminate__()) { break; } ");
            return eval(__jsCode__);
        }.call(thisObj, codeStr);
    }
}
