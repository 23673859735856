//@author travis
import { DataAccess } from "../dataaccess/data.access";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { ExtendUtil } from "../util/extend-util";
import { Item } from "../pojo/Item";

export class FavoriteService {
    private static TYPEID_MAP: any = {
        //favorite-events
        11: 1,
        //favorite-spaces
        14: 4,
        //favorite-resources
        16: 6,
        //favorite-event-searches
        101: 11,
        //favorite-organization-searches
        102: 16,
        //favorite-space-searches
        104: 14,
        //favorite-resource-searches
        106: 15,
        //favorite-task-searches
        110: 10,
        //favorite-reports
        1000: 9,
    };

    private static composeUrl(item: { itemId: number; itemTypeId: Item.Id }, caller: string) {
        return DataAccess.injectCaller(
            "/favorites.json?object_type=" +
                (FavoriteService.TYPEID_MAP[item.itemTypeId] || item.itemTypeId) +
                "&object_id=" +
                item.itemId,
            caller,
        );
    }

    @Invalidate({
        serviceName: "FavoriteService",
        methodName: "isFavorite",
        objectFunc: (args) => [FavoriteService.TYPEID_MAP[args[0].itemTypeId] || args[0].itemTypeId, args[0].itemId],
    })
    @Invalidate({
        startsWith: "SpaceService",
        patternFunc: (args) => args[0].itemId,
        validate: (args) => args[0].itemTypeId === 4,
    })
    @Invalidate({
        serviceName: "SpaceService",
        methodName: "getSpacesBySearchQuery",
        validate: (args) => args[0].itemTypeId === 4,
    })
    @Invalidate({
        startsWith: "ResourceService",
        patternFunc: (args) => args[0].itemId,
        validate: (args) => args[0].itemTypeId === 6,
    })
    @Invalidate({
        serviceName: "ResourceService",
        methodName: "getResourcesBySearchQuery",
        validate: (args) => args[0].itemTypeId === 6,
    })
    public static add(item: { itemId: number; itemTypeId: Item.Id }) {
        return DataAccess.put(FavoriteService.composeUrl(item, "FavoriteService.add"));
    }

    @Invalidate({
        serviceName: "FavoriteService",
        methodName: "isFavorite",
        objectFunc: (args) => [FavoriteService.TYPEID_MAP[args[0].itemTypeId] || args[0].itemTypeId, args[0].itemId],
    })
    @Invalidate({
        startsWith: "SpaceService",
        patternFunc: (args) => args[0].itemId,
        validate: (args) => args[0].itemTypeId === 4,
    })
    @Invalidate({
        serviceName: "SpaceService",
        methodName: "getSpacesBySearchQuery",
        validate: (args) => args[0].itemTypeId === 4,
    })
    @Invalidate({
        startsWith: "ResourceService",
        patternFunc: (args) => args[0].itemId,
        validate: (args) => args[0].itemTypeId === 6,
    })
    @Invalidate({
        serviceName: "ResourceService",
        methodName: "getResourcesBySearchQuery",
        validate: (args) => args[0].itemTypeId === 6,
    })
    public static remove(item: { itemId: number; itemTypeId: Item.Id }) {
        return DataAccess.delete(FavoriteService.composeUrl(item, "FavoriteService.remove"));
    }

    public static toggle(item: any) {
        // isUnstar seems to indicate if we should unstar the item, other contexts may wish to toggle the starred state
        // therefore, if isFav == true, then toggle it (unstar it), else, add it
        return FavoriteService[item.isUnstar || item.isFav ? "remove" : "add"](item).then(function (resp) {
            ExtendUtil.extend(item.itemId, item.itemTypeId, { favorite: item.favorite, isFav: item.isFav });
            return resp;
        });
    }

    @Cache({ immutable: true, targetName: "FavoriteService" })
    public static isFavorite(itemTypeId: number, itemId: number) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/favorites/isfavorite.json?itemTypeId=" + itemTypeId + "&itemId=" + itemId,
                "FavoriteService.isFavorite",
            ),
        );
    }
}
