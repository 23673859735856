//@author: devin
import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { Cache } from "../decorators/cache.decorator";
import { Fls } from "../pojo/Fls";

export class FlsService {
    @Cache({ targetName: "FlsService" })
    public static getFls() {
        return DataAccess.get("/fls.json?caller=pro").then((data) => {
            return FlsService.formatFls(data);
        });
    }

    public static formatFls(data: any) {
        const ret: any = {};
        if (data && data.fls && data.fls.permission) {
            let permissions = S25Util.array.forceArray(data.fls.permission) as any[];
            for (let i = 0; i < permissions.length; i++) {
                ret[permissions[i].internal_name] = permissions[i].access_level;
            }
        }
        return ret as Fls;
    }

    @Cache({ targetName: "FlsService" })
    public static getMLEvent() {
        return FlsService.getFls().then((fls) => {
            return fls.ML_EVENT;
        });
    }

    @Cache({ targetName: "FlsService" })
    public static getMLSpace() {
        return FlsService.getFls().then((fls) => {
            return fls.ML_SPACE;
        });
    }
}
