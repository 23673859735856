import { jSith } from "../util/jquery-replacement";
import { S25Util } from "../util/s25-util";
import { EventService } from "./event.service";
import { ResourceSpaceAvailService } from "./resource.space.avail.service";
import { BpeService } from "../modules/bpe/bpe.service";
import { EventEmailService } from "./event.email.service";
import { EventSync } from "../util/event.sync.util";
import { EvddItemApi } from "../modules/event-details/evdd.item.api";
import { UserprefService } from "./userpref.service";
import { OlsService } from "./ols.service";
import { S25Const } from "../util/s25-const";
import { FlsService } from "./fls.service";
import { PreferenceService } from "./preference.service";
import { Timeout } from "../decorators/timeout.decorator";
import { Event } from "../pojo/Event";
import { BpeScenarioTypes } from "../modules/bpe/BpeI";
import { Task } from "../pojo/Task";

export const EventStateConst = {
    updateState: "s25-event-state-update-state",
    stateMap: {
        0: "Draft",
        1: "Tentative",
        2: "Confirmed",
        3: "Sealed",
        98: "Denied",
        99: "Cancelled",
    } as { [key: number]: string },
    transitionMap: {
        0: { 0: true, 1: true, 2: true, 3: true, 98: true, 99: true },
        1: { 0: true, 1: true, 2: true, 3: true, 98: true, 99: true },
        2: { 0: true, 1: true, 2: true, 3: true, 98: true, 99: true },
        3: { 0: true, 1: true, 2: true, 3: true, 98: true, 99: true },
        98: { 0: true, 1: true, 2: true, 3: true, 98: true, 99: true },
        99: { 0: true, 1: true, 2: true, 3: true, 98: true, 99: true },
    },
    warningMap: {
        3: "sealed_warning",
        98: "denied_warning",
        99: "cancelled_warning",
    },
    allEventStates: [
        Event.State.Ids.Draft,
        Event.State.Ids.Tentative,
        Event.State.Ids.Confirmed,
        Event.State.Ids.Sealed,
        Event.State.Ids.Denied,
        Event.State.Ids.Cancelled,
    ],
};
export class EventStateApi {
    // public static updateState = S25Util.directive.createApiFnRetFn(EventStateConst.updateState);
    public static updateState = function (details: any) {
        dispatchEvent(new CustomEvent(EventStateConst.updateState, { detail: details }));
    };
}
export class EventStateChangeService {
    @Timeout
    public static changeState(
        eventId: number,
        newStateId: Event.State.Id,
        newParentId?: number,
        bpeEmailType?: BpeScenarioTypes,
    ) {
        //reservations needed to check availability
        //text needed to extract draft list
        //customers needed for BPEs to run that have org info (see bpe-util getVariables method)
        return S25Util.all({
            eventData: BpeService.getEventData(eventId),
            currId: UserprefService.getContactId(),
        }).then(function (resp) {
            let eventData = resp.eventData;
            let origEventData = S25Util.deepCopy(eventData);
            newStateId = S25Util.toInt(newStateId);
            var curStateId = parseInt(eventData.state);
            var draftConflictsPromise = jSith.when({ hasConflicts: false });
            if (newParentId) {
                eventData.parent_id = newParentId;
            }
            eventData.status = "mod";
            eventData.state = newStateId;

            if (newStateId === 99) {
                //if event is canceled, cancel occs, delete bindings and cancel
                jSith.forEach(eventData.todo, function (_, todo) {
                    if (
                        todo.todo_subtype === 99 &&
                        todo.cur_todo_state != 99 &&
                        todo.cur_assigned_to_id == resp.currId
                    ) {
                        todo.status = "mod";
                        todo.cur_todo_state = 2;
                    }
                });

                jSith.forEach(eventData.profile, function (_, p) {
                    p.status = "mod";
                    jSith.forEach(p.reservation, function (_, rsrv) {
                        rsrv.status = "mod";
                        rsrv.reservation_state = 99;
                    });

                    jSith.forEach(p.binding_reservation, function (_, rsrv) {
                        rsrv.status = "del";
                    });
                });
            } else if ([1, 2, 3].indexOf(newStateId) > -1 && [0, 98, 99].indexOf(curStateId) > -1) {
                //uncancel
                var drafts = S25Util.propertyGet(
                    S25Util.propertyGetParentWithChildValue(eventData, "text_type_id", "9"),
                    "text",
                );
                drafts = S25Util.parseSpaceResourcePreferences(drafts);

                jSith.forEach(eventData.profile, function (_, p) {
                    p.status = "mod";
                    jSith.forEach(p.reservation, function (_, rsrv) {
                        rsrv.status = "mod";
                        rsrv.reservation_state = 1;
                    });

                    //change from draft to tentative, confirmed, sealed
                    if (curStateId === 0) {
                        drafts &&
                            drafts.reservation_list &&
                            jSith.forEach(drafts.reservation_list.reservation, function (_, draftRsrv) {
                                //need to find profileRsrv reservation for same reservation id...
                                //then, push space_reservation into space_reservation list, or make one
                                //then, push resource_reservation into space_reservation list, or make one
                                //then, reset spaceRsrv and resourceRsrv so they have these additional entries
                                //note: bc we are pushing to a profile from profileRsrv, eventData is updated by "reference" (ref passed as val)
                                var profileRsrvForId = S25Util.propertyGetParentWithChildValue(
                                    p.reservation,
                                    "reservation_id",
                                    draftRsrv.reservation_id,
                                );
                                if (profileRsrvForId) {
                                    jSith.forEach(draftRsrv.space_reservation, function (_, draftSpRsrv) {
                                        profileRsrvForId.space_reservation = profileRsrvForId.space_reservation || [];
                                        var share =
                                            draftSpRsrv.share && draftSpRsrv.share.nil
                                                ? null
                                                : draftSpRsrv.share || "F"; //could be an object, prevent that...
                                        var layoutId =
                                            draftSpRsrv.layout_id && draftSpRsrv.layout_id.nil
                                                ? null
                                                : draftSpRsrv.layout_id;
                                        var spaceInstructions =
                                            draftSpRsrv.space_instructions && draftSpRsrv.space_instructions.nil
                                                ? null
                                                : draftSpRsrv.space_instructions;
                                        profileRsrvForId.space_reservation.push({
                                            space_id: draftSpRsrv.space_id,
                                            layout_id: layoutId,
                                            share: share || "F",
                                            space_instructions: spaceInstructions,
                                        });
                                    });

                                    jSith.forEach(draftRsrv.resource_reservation, function (_, draftRsRsrv) {
                                        profileRsrvForId.resource_reservation =
                                            profileRsrvForId.resource_reservation || [];
                                        var quantity =
                                            draftRsRsrv.quantity && draftRsRsrv.quantity.nil
                                                ? null
                                                : draftRsRsrv.quantity;
                                        var resourceInstructions =
                                            draftRsRsrv.resource_instructions && draftRsRsrv.resource_instructions.nil
                                                ? null
                                                : draftRsRsrv.resource_instructions;
                                        profileRsrvForId.resource_reservation.push({
                                            resource_id: draftRsRsrv.resource_id,
                                            quantity: quantity,
                                            resource_instructions: resourceInstructions,
                                        });
                                    });
                                }
                            });
                    }
                });

                if (curStateId === 0) {
                    draftConflictsPromise = ResourceSpaceAvailService.getAvailabilityByEvent(eventData);
                }
            }

            return draftConflictsPromise.then(
                function (ret) {
                    if (!ret.hasConflicts) {
                        if ([1, 2, 3].indexOf(newStateId) > -1 && curStateId === 0) {
                            //draft -> tentative, confirmed, sealed
                            jSith.forEach(eventData.profile, function (_, p) {
                                p.status = "mod";
                                jSith.forEach(p.reservation, function (_, rsrv) {
                                    rsrv.status = "mod";
                                    jSith.forEach(rsrv.space_reservation, function (_, spRsrv) {
                                        spRsrv.status = "new";
                                    });
                                    jSith.forEach(rsrv.resource_reservation, function (_, rsRsrv) {
                                        rsRsrv.status = "new";
                                    });
                                });
                            });
                        }

                        jSith.forEach([].concat(eventData.profile), function (_, p) {
                            if (p.status === "est") {
                                eventData.profile.splice(eventData.profile.indexOf(p), 1);
                            }
                        });

                        if (eventData.profile.length === 0) {
                            delete eventData.profile;
                        }

                        delete eventData.text; //text not needed in PUT
                        delete eventData.role; //roles not needed in PUT

                        const putPromise = EventService.putEvent(eventId, eventData);

                        bpeEmailType = bpeEmailType || "event-state-change";
                        const postEventDataPromise = putPromise.then((resp) => {
                            return resp.events?.event[0];
                        });
                        BpeService.runScenariosAfterChange({
                            preEventData: origEventData,
                            postEventDataPromise,
                            source: bpeEmailType,
                        });

                        BpeService.runScenariosAfterChange({
                            preEventData: origEventData,
                            postEventDataPromise,
                            source: "cancelTodos",
                            validate: (preData, postData) => {
                                // Run scenarios only if cancelling OR completing the task
                                if (bpeEmailType === "task") return true;
                                const preState = Number(preData.state);
                                const postState = Number(postData.state);
                                return postState === Task.States.Cancelled && preState !== Task.States.Cancelled;
                            },
                        });

                        return putPromise.then(
                            function (resp) {
                                if (resp.events) {
                                    //email changes to people
                                    EventEmailService.sendEventChangedEmail(eventId);

                                    //update this on event summary
                                    EventSync.broadcastEventStateChange(eventId && eventId.toString(), newStateId);
                                    //update this on event details, if event exists, etc
                                    EvddItemApi.itemMerge({
                                        itemTypeId: 1,
                                        parentTypeId: 1106,
                                        eventId: eventId,
                                        extend: { itemId: newStateId, itemName: EventStateConst.stateMap[newStateId] },
                                    });
                                    //update this on any other event state components open with the same event id
                                    EventStateApi.updateState({ eventId: eventId, newStateId: newStateId });
                                    return { response: "success" };
                                } else {
                                    S25Util.showError(resp, null);
                                    return jSith.reject({ response: "error" });
                                }
                            },
                            function (error) {
                                var jsonErr = error && error.data && S25Util.prettifyJson(error.data);
                                if (S25Util.valueFind(jsonErr, "msg_id", "EV_I_MULTPAR")) {
                                    var parentCandidates = S25Util.propertyGet(jsonErr, "parent");
                                    return jSith.reject({ response: "multipleParents", data: parentCandidates });
                                } else if (newStateId === 99) {
                                    //If the failed has a cancel request assignee, capture error and generate request cancellation
                                    return EventStateChangeService.getTaskAssignee(eventId).then(
                                        function (assigneeId) {
                                            if (assigneeId) throw error && error.error;
                                            else if (S25Util.valueFind(jsonErr, "msg_id", "EV_E_NOPERM")) {
                                                //the message around uncancellilng from WS is confusing to users so clean it up ANG-4437
                                                S25Util.showError(
                                                    "Unable to cancel due to insufficient permission to unassign. Please contact an administrator.",
                                                );
                                            } else {
                                                //Show any other errors ANG-4559 eg. locks
                                                S25Util.showError(error);
                                            }
                                        },
                                        () => {
                                            S25Util.showError(error);
                                        },
                                    );
                                } else {
                                    S25Util.showError(error);
                                }
                            },
                        );
                    } else {
                        //we have draft conflicts
                        return { response: "conflicts", data: ret };
                    }
                },
                function (error) {
                    console.error(error);
                },
            );
        });
    }

    /*Finds if current user can change event state. To change state, user must have
        edit access on event (OLS or ownership),
        sufficient FLS,
        Available event types from sys_defaults
        Users can cancel their own express events
    Taken from s25-event-states.js (S25EventStates directive)
    */

    /*
    Find the available event states for the current user given an event.
    Take into consideration:
        Prefs, role on event, is event express
        fls and ols
    */
    @Timeout
    public static getAllowedStates(itemId: number, eventState?: number, blacklistedStates?: number[]) {
        blacklistedStates = blacklistedStates || [];
        return S25Util.all({
            eventState: S25Util.isUndefined(eventState) && itemId && EventService.getEventState(itemId),
            allowedEventStates: UserprefService.getAllowedStates(),
            isExpress: itemId && EventService.isExpress(itemId),
            isCurrentRequester: itemId && EventService.isCurrentRequester(itemId),
            prefs: PreferenceService.getPreferences(["config_BPE_event_get", "SpbkEvState"]),
            fls: FlsService.getFls(),
            olsEdit: itemId && OlsService.getOls([itemId], S25Const.itemName2Id.event, "edit"),
        }).then((resp) => {
            //express state change can happen even if user lacks a lot of perms so long as the event is express and the user is the requester
            let expressStateChangeOverride = resp.isExpress && resp.isCurrentRequester;
            if (expressStateChangeOverride && resp.allowedEventStates.indexOf(99) === -1) {
                resp.allowedEventStates.push(99);
            }

            eventState = parseInt(S25Util.coalesce(eventState, resp.eventState));

            //Extract pertinant FLS
            let fls = resp.fls;
            let flsCanEditEventDrafts = fls && ["F", "C"].indexOf(fls.EVENT_DRAFT) > -1;
            let flsEventStateF = fls && fls.EVENT_STATE === "F";
            let flsCanAccessRose = fls && fls.SPEEDBOOK === "F";
            let flsCanEditEventState = fls && ["F", "C"].indexOf(fls.EVENT_STATE) > -1;
            let flsCanEditEvents = fls && ["F", "C"].indexOf(fls.EVENT_EVS) > -1;

            let olsEdit = resp.olsEdit && resp.olsEdit[0];
            let olsAccess = olsEdit && ["F", "C"].indexOf(olsEdit.access_level) > -1;

            let prefs = resp.prefs;
            //Event Form configuration type. Eithe "configurations" or "default". All schools should be using "configurations"
            let bpeEventGet = prefs && prefs.config_BPE_event_get && prefs.config_BPE_event_get.value;

            let allowedEventStates = (bpeEventGet === "configurations" ? resp.allowedEventStates : [0, 1, 2, 3, 98, 99])
                .filter(function (s: number) {
                    //if state is NOT draft, sure, allow it if in allowed list
                    //if state IS draft, only allow it if the user can also edit draft events!
                    return s !== 0 || (s === 0 && flsCanEditEventDrafts);
                })
                .filter(function (s: number) {
                    //if current event state is 98 or 99, user can only change if they have EVENT_STATE in F
                    //else, they can change to whatever is in allowedEventStates
                    return (
                        (flsEventStateF && [98, 99].indexOf(eventState) > -1) || [0, 1, 2, 3].indexOf(eventState) > -1
                    );
                })
                .filter(function (s: any) {
                    //not a blacklisted state
                    return blacklistedStates.indexOf(s) === -1;
                });

            var configAllowsState = allowedEventStates.indexOf(eventState) > -1;

            let canChangeState =
                ((flsCanAccessRose &&
                    flsCanEditEventState &&
                    configAllowsState &&
                    ((eventState === 0 && flsCanEditEventDrafts) || (eventState !== 0 && flsCanEditEvents))) ||
                    expressStateChangeOverride) &&
                olsAccess;
            return canChangeState ? allowedEventStates : [];
        });
    }

    /*
        Gets the task cancellation assginee.
        -Only requester can can create task,
        -Assginee is the scheduler unless scheduler == requester, then use event owner
    */
    @Timeout
    public static getTaskAssignee(eventId: number): Promise<number> {
        return S25Util.all({
            model: window.angBridge.$injector.get("EvdetailService").getGenericModel(eventId),
            isCurrentRequester: EventService.isCurrentRequester(eventId),
            requesterId: EventService.getRequester(eventId),
            schedulerId: EventService.getScheduler(eventId),
        }).then((resp) => {
            let details = resp.model && resp.model.defn;
            let ownerId =
                details.eventOwner &&
                details.eventOwner.item &&
                details.eventOwner.item.item &&
                details.eventOwner.item.item[0] &&
                details.eventOwner.item.item[0].itemId;
            let requesterId = resp.requesterId;
            let schedulerId = resp.schedulerId;

            if (resp.isCurrentRequester) {
                return schedulerId === requesterId ? (schedulerId === ownerId ? null : ownerId) : schedulerId;
            } else {
                return null;
            }
        });
    }
}
