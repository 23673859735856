import { DataAccess } from "../dataaccess/data.access";
import { Timeout } from "../decorators/timeout.decorator";
import { Cache, Invalidate } from "../decorators/cache.decorator";

export interface EventPreferences {
    panelId?: number;
    group?: Group[];
}

export interface Group {
    item?: Item[];
    typeId?: number;
    queryId?: number;
}

//itemTypeId  // 410 = Feature, 423 = partition, 407 = location,  607 = resource
export interface Item {
    itemId?: number;
    itemName?: string;
    quantity?: number;
    quantityMax?: number;
    itemTypeId?: number;
}

export class EventPreferencesService {
    @Timeout
    @Cache({ immutable: true, targetName: "EventPreferencesService" })
    public static getPreferences(eventId: number) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/event/detail/pref.json?" + DataAccess.composeParam("event_id", eventId, true),
                "EventPreferencesService.getPreferences",
            ),
        ).then(function (data) {
            return data.pref;
        });
    }

    @Invalidate({ serviceName: "EventPreferencesService" })
    @Timeout
    public static putPreferences(eventId: number, panelId: number, typeId: number, queryId: number, items: Item[]) {
        return DataAccess.put(
            DataAccess.injectCaller(
                "/event/detail/pref.json?" +
                    DataAccess.composeParam("event_id", eventId, true) +
                    DataAccess.composeParam("panelId", panelId) +
                    DataAccess.composeParam("typeId", typeId) +
                    DataAccess.composeParam("query_id", queryId),
                "EventPreferencesService.putPreferences",
            ),
            {
                data: { item: items },
            },
        );
    }

    @Invalidate({ serviceName: "EventPreferencesService" })
    @Timeout
    public static deletePreferences(eventId: number, panelId: number, typeId: number, queryId: number) {
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/event/detail/pref.json?" +
                    DataAccess.composeParam("event_id", eventId, true) +
                    DataAccess.composeParam("panelId", panelId) +
                    DataAccess.composeParam("typeId", typeId) +
                    DataAccess.composeParam("query_id", queryId),
                "EvdPrefDao.delete",
            ),
        );
    }
}
