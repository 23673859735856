import { S25Util } from "../util/s25-util";
import { jSith } from "../util/jquery-replacement";
import { S25Datefilter } from "../modules/s25-dateformat/s25.datefilter.service";
import { EventStateConst } from "./event.state.change.service";
import { ModelBeanElement } from "../pojo/ModelBeanElement";

export class EventDetailsListService {
    public static getCacheParams(mockScope: any) {
        return {
            include: "customers+reservations+attributes+text",
            query_id: mockScope.modelBean.query_id,
        };
    }

    public static getModel(
        events: any[],
        grouping: "day" | "week" | "month" | "schedule",
        elementProperties: ModelBeanElement[],
        weekstart: number, // 0 is Sunday
        groupNameDateFormat: string,
        elementDateFormat: string,
        startDateFormat: string,
        endDateFormat: string,
        timeInline: boolean,
        preferOrgTitle: boolean,
        allowSubscribe: boolean,
    ) {
        let ret: { [key: string]: any } = {
            groupsDict: {
                //hash of groups
                //`group-hash`: { groupUuid: string, groupName: string, elements: [{itemId, rsrvId, properties: [...event-data...]}] }
            },
            groups: [], //array of the same groups
        };

        elementProperties.sort(S25Util.shallowSort("order", true));
        elementDateFormat = elementDateFormat || "EEEE, MMMM d, h:mma";
        startDateFormat = startDateFormat || elementDateFormat;
        endDateFormat = endDateFormat || elementDateFormat;
        timeInline = timeInline || false;
        weekstart = weekstart || 0; //sunday by default
        preferOrgTitle = preferOrgTitle || false;
        allowSubscribe = allowSubscribe || false;

        jSith.forEach(events, (_, event) => {
            let description = S25Util.propertyGetParentWithChildValue(event.event_text, "text_type_id", 1);
            let primaryOrganization = S25Util.propertyGetParentWithChildValue(event.organization, "primary", "T");

            jSith.forEach(event.profile, (_, profile) => {
                jSith.forEach(profile.reservation, (_, reservation) => {
                    let group = null;
                    let groupName = null;

                    let rsrvDateTimes = S25Util.extractReservationDateTimes(reservation);
                    let startDt = rsrvDateTimes.evStartDt;
                    let endDt = rsrvDateTimes.evEndDt;

                    if (grouping === "day") {
                        group = S25Util.date.toS25ISODateStr(startDt);
                        groupNameDateFormat = groupNameDateFormat || "fullDate";
                    } else if (grouping === "week") {
                        group = S25Util.date.toS25ISODateStr(S25Util.date.firstDayOfWeek(startDt, weekstart));
                        groupNameDateFormat = groupNameDateFormat || "fullDate";
                    } else if (grouping === "month") {
                        group = S25Util.date.toS25ISODateStr(S25Util.date.firstDayOfMonth(startDt));
                        groupNameDateFormat = groupNameDateFormat || "MMMM y"; //eg March 2021
                    }

                    if (groupNameDateFormat) {
                        groupName = S25Datefilter.transform(S25Util.date.parse(group), groupNameDateFormat);
                    } else {
                        groupName = group;
                    }

                    let groupObj = null;
                    if (!ret.groupsDict[group]) {
                        groupObj = {
                            groupUuid: group,
                            groupName: groupName,
                            elements: [],
                        };
                        ret.groupsDict[group] = groupObj;
                        ret.groups.push(groupObj);
                    } else {
                        groupObj = ret.groupsDict[group];
                    }

                    let element: { [key: string]: any } = {
                        itemId: event.event_id,
                        rsrvId: reservation.reservation_id,
                        properties: [],
                        propertyDict: {},
                    };

                    jSith.forEach(elementProperties, (i: number, propObj: ModelBeanElement) => {
                        let prop = propObj.name;
                        let label = propObj.display;
                        if (prop === "name") {
                            element.propertyDict[prop] = { key: prop, value: event.event_name, label: label, order: i };
                            element.properties.push(element.propertyDict[prop]);
                        } else if (prop === "title" && event.event_title) {
                            element.propertyDict[prop] = {
                                key: prop,
                                value: event.event_title,
                                label: label,
                                order: i,
                            };
                            element.properties.push(element.propertyDict[prop]);
                        } else if (prop === "titleOrName") {
                            element.propertyDict[prop] = {
                                key: prop,
                                value: event.event_title ? event.event_title : event.event_name,
                                label: label,
                                order: i,
                            }; //s25Util.coalesce(event.event_title, event.event_name)
                            element.properties.push(element.propertyDict[prop]);
                        } else if (prop === "start") {
                            element.propertyDict[prop] = {
                                key: prop,
                                value: S25Datefilter.transform(startDt, startDateFormat),
                                rawValue: startDt,
                                label: label,
                                order: i,
                                isInline: timeInline,
                            };
                            element.properties.push(element.propertyDict[prop]);
                        } else if (prop === "end") {
                            if (timeInline) {
                                element.propertyDict[prop] = {
                                    key: prop,
                                    value: "\xa0-\xa0 " + S25Datefilter.transform(endDt, endDateFormat),
                                    rawValue: endDt,
                                    label: label,
                                    order: i,
                                    isInline: timeInline,
                                };
                            } else {
                                element.propertyDict[prop] = {
                                    key: prop,
                                    value: S25Datefilter.transform(endDt, endDateFormat),
                                    rawValue: endDt,
                                    label: label,
                                    order: i,
                                };
                            }
                            element.properties.push(element.propertyDict[prop]);
                        } else if (prop === "setup" && rsrvDateTimes.hasSetup) {
                            let startDtFormat = "h:mma";
                            let endDtFormat = "h:mma";
                            if (rsrvDateTimes.setupEvStartDiffDay || rsrvDateTimes.setupStartEndDiffDay) {
                                startDtFormat = elementDateFormat;
                            }
                            if (rsrvDateTimes.setupStartEndDiffDay) {
                                endDtFormat = elementDateFormat;
                            }
                            element.propertyDict[prop] = {
                                key: prop,
                                value:
                                    S25Datefilter.transform(rsrvDateTimes.setupStartDt, startDtFormat) +
                                    "\xa0-\xa0 " +
                                    S25Datefilter.transform(rsrvDateTimes.setupEndDt, endDtFormat),
                                rawValue: rsrvDateTimes.setupStartDt + ", " + rsrvDateTimes.setupEndDt,
                                label: label,
                                order: i,
                            };
                            element.properties.push(element.propertyDict[prop]);
                        } else if (prop === "takedown" && rsrvDateTimes.hasTakedown) {
                            let startDtFormat = "h:mma";
                            let endDtFormat = "h:mma";
                            if (rsrvDateTimes.takedownEvEndDiffDay || rsrvDateTimes.takedownStartEndDiffDay) {
                                startDtFormat = elementDateFormat;
                            }
                            if (rsrvDateTimes.setupStartEndDiffDay) {
                                endDtFormat = elementDateFormat;
                            }
                            element.propertyDict[prop] = {
                                key: prop,
                                value:
                                    S25Datefilter.transform(rsrvDateTimes.takedownStartDt, startDtFormat) +
                                    "\xa0-\xa0 " +
                                    S25Datefilter.transform(rsrvDateTimes.takedownEndDt, endDtFormat),
                                rawValue: rsrvDateTimes.setupStartDt + ", " + rsrvDateTimes.setupEndDt,
                                label: label,
                                order: i,
                            };
                            element.properties.push(element.propertyDict[prop]);
                        } else if (prop === "state") {
                            element.propertyDict[prop] = {
                                key: prop,
                                value: EventStateConst.stateMap[parseInt(event.state)],
                                rawValue: event.state,
                                label: label,
                                order: i,
                            };
                            element.properties.push(element.propertyDict[prop]);
                        } else if (prop === "descr" && description && S25Util.isDefined(description.text)) {
                            element.propertyDict[prop] = {
                                key: prop,
                                value: S25Util.toStr(description.text),
                                label: label,
                                order: i,
                            };
                            element.properties.push(element.propertyDict[prop]);
                        } else if (prop === "expected") {
                            element.propertyDict[prop] = {
                                key: prop,
                                value: profile.expected_count,
                                label: label,
                                order: i,
                            };
                            element.properties.push(element.propertyDict[prop]);
                        } else if (prop === "registered") {
                            element.propertyDict[prop] = {
                                key: prop,
                                value: profile.registered_count,
                                label: label,
                                order: i,
                            };
                            element.properties.push(element.propertyDict[prop]);
                        } else if (prop === "sponsor" && primaryOrganization) {
                            if (preferOrgTitle) {
                                element.propertyDict[prop] = {
                                    key: prop,
                                    value: S25Util.coalesce(
                                        primaryOrganization.organization_title,
                                        primaryOrganization.organization_name,
                                    ),
                                    label: label,
                                    order: i,
                                };
                                element.properties.push(element.propertyDict[prop]);
                            } else {
                                element.propertyDict[prop] = {
                                    key: prop,
                                    value: primaryOrganization.organization_name,
                                    label: label,
                                    order: i,
                                };
                                element.properties.push(element.propertyDict[prop]);
                            }
                        } else if (prop === "locations" && reservation.space_reservation) {
                            let locations: any[] = [];
                            jSith.forEach(reservation.space_reservation, (_, spRsrv) => {
                                if (spRsrv && spRsrv.space) {
                                    locations.push({
                                        itemId: spRsrv.space_id,
                                        itemTypeId: 4,
                                        itemName: spRsrv.space.space_name,
                                        itemDesc: spRsrv.space.formal_name,
                                    });
                                }
                            });
                            element.propertyDict[prop] = {
                                key: prop,
                                values: locations,
                                rawValue: reservation.space_reservation,
                                isArray: true,
                                label: label,
                                order: i,
                            };
                            element.properties.push(element.propertyDict[prop]);
                        }
                    });

                    groupObj.elements.push(element);
                });
            });
        });

        ret.groups.sort(S25Util.shallowSort("groupUuid"));
        return ret;
    }
}
